.mpd-repository-analysis-card {
    box-shadow: 8px 2px 8px 0px rgb(0 0 0 / 23%);
    background-color: white;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // overflow-y: hidden;

    p {
        margin: 5px 0;
    }
    .right-panel-newArc{
        display: flex;
        margin-left: auto;
        align-items: center;
        column-gap: 10px;
    }
    .left-panel-newArc{
        display: flex;
        align-items: flex-start;
        width: 100%;
        .details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            width: 440px;
            .creation-updation-time {
                color: #808080;
                margin-top:5px;
                padding:0;
            }
            .status{
                padding:0;
                margin-bottom: -10px;
                margin-top:2px;
            }
            .status-chip{
               margin-top:-30px;
            }
        }
        .rebuild {
            height:32px;
            display: flex;
            align-items: center;
            gap:2px;
        }
        // for sync icon
        .rebuild svg {
            margin-right: 2px;
        }
        .action-btn-cls{
            height:32px;
        }
        .export-btn-cls{
            color: #da1884;
            height:32px;
            border-radius: 3px;
            border: 1px solid #da1884;
            display: inline-flex;
            align-items: center;
            padding:2px 8px;
            gap:2px;
        }
        
        .export-btn-cls svg{
            margin:0;
            padding:0;
            gap:0;
            width:16px;
            height:16px;
        }
        .delete-btn-cls{
            height:32px;
            width:32px;
            border-color: #cbc7c7;
        }
        .title {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            font-size: 0.9rem;
    font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
            p {
                overflow-wrap: break-word;
                word-break: break-word;
            };
            span {
                margin-left: 5px;
            }
        }
        .button-newArc{
            display: flex;
            column-gap: 10px;
        }
    }
    
}

@media (max-width: 1200px) {
    .mpd-repository-analysis-card {
        flex-direction: column;

        .right-panel-newArc {
            margin-top: 0.5rem;
            justify-content: flex-end;
            width: 100%; 
        }
    };
}