@import '../../globalMixins.scss';
@import '../../mixins.scss';

.mpd-filter-drawer-cls {
    .arrow-button {
        @include drawer-arrows;
        margin-top: 10px;
    }
}

.mpd-filter-drawer {
    @include drawer-opened;
    min-width: 305px;
    width: 27vw;
    @include for-desktop-up {
        max-width: 27vw;
    }
    @include for-big-desktop-up {
        max-width: 20vw;
    }
    margin-top: calc(13% + 3px);
    &.banner-open {
        @include for-desktop-up {
            margin-top: calc(22% + 27px);
        }
        @include for-big-desktop-up {
            margin-top: calc(20% + 9px);
        }
    }
    .mpd-spinner-cls {
        margin-top: 75%;
        margin-left: 49%;
    }
    .effMaintanance-cls {
        margin-top: 7px;
    }
    .effectivity-cls {
        margin-top: 5px;
    }
    .mpd-msn-text-cls {
        margin-right: 30px;
    }
    .radio-cls-style {
        width: 243px;
        .effective-radio-des-cls {
            visibility: hidden;
            position: absolute;
            display: block;
        }
        .effective-radio-cls:hover + .effective-radio-des-cls {
            visibility: visible;
            position: relative;
            @include radioDescription;
        }
        .radioChecked-cls {
            @include radioDescription;
        }
    }
    .submit-btn {
        margin-top: 20px;
        width: 100%;
    }
}