.help-popup {
      position: fixed;
      min-width:180px; 
      margin-top:12px;
      right:3%;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      .help-popup-content{
        display:flex;
        color: #000;;
        margin-top:4px;
        min-height:40px;
        justify-content: space-between;
        border-bottom: 1px solid #d4d4d4;
    }
    .help-popup-close{
        color:#000;
        margin-right:5px;
    }
    .help-popup-link{
        color:#000;
        margin-left:8%;
        margin-top:4%;
        padding:0px;
        
    }
    .help-popup-link:hover{
        color:#3e3e3e;
        }
    .help-popup-version{
        color:#817f7f;
        margin-left:5%;
        margin-top:-10px;
    }
    .help-popup-chip{
        background-color: #e2e9ed;
        margin-top:-5px;
        color:#000;
        font-weight: 100;
        margin-bottom:8%;
    }

  
    }
    